import React, { useEffect } from "react"
import { Helmet } from "react-helmet"

// Components
import { Link } from "gatsby"

const OosTy = () => {
  // Scroll to top on mount
  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
    })
  }, [])

  return (
    <>
      <Helmet>
        <title>Thank You | The Betty Rocker</title>
      </Helmet>
      <section className="bg--thankyou thankyou-container overflow--hidden d-flex align-items-start align-items-md-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-7 offset-md-5">
              <h2 className="h2--sm txt--sm-left mb--20">
                <span className="txt--pink">Thank you</span> for letting us know
                you're interested in this product.
              </h2>
              <p className="mb--0">We're excited to get it to you ASAP.</p>
              <p>We'll send you an email when it's back in stock!</p>

              <Link to="/" className="btn btn--thankyou">
                continue shopping
              </Link>
            </div>
          </div>

          <img
            loading="lazy"
            src="/images/thankyou-betty.png"
            alt=""
            className="no-shadow thankyou-img"
            width={447}
            height={525}
          />
        </div>
      </section>
    </>
  )
}
export default OosTy
